html {
  touch-action: manipulation;
}

body, html {
  height: 100%;
}

.app {
  height: 100%;
  /* min-width: 720px; min scale */
  min-width: 320px; /* iphone 5 */
}

@media print {
  body, html {
    height: inherit;
    overflow-x: visible;
  }

  .app {
    height: inherit;
  }
}
