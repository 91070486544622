
.bizquiz {
  height: 100%;
}

@media print {
  .bizquiz {
    height: inherit;
  }
}

button, input {
  border: 0;
}

.ory-cell-leaf {
  padding: 8px;
}
.ory-cell-leaf {
  margin: 12px;
}
